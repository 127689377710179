/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const TourCard = props => {
  return (
    <Link
      to={`/workshops${props.tour.handle}`}
      className={`tour-card__item relative my-4 grid-end--4 grid-end--xs-12 ${props.className}`}
      style={props.style}
    >
      <div className="relative">
        <Img
          className="tour-card__item--image"
          fluid={props.tour.featuredImage.childImageSharp.fluid}
          loading="eager"
        />
      </div>

      <div className="flex flex-col">
        <h5 className="tour-card__item--title f5 font-bold mt-2">
          {props.tour.title}
        </h5>

        {props.tour.tourType && (
          <span className="tour-card__item--title b2 mt-2">
            {props.tour.tourType}
          </span>
        )}

        {props.tour.tourOptions && props.tour.tourOptions[0].price && (
          <span className="tour-card__item--title b2 mt-2">
            {props.tour.tourOptions[0].price}
          </span>
        )}

        {props.tour.tourOptions && props.tour.tourOptions[0].spotsTotal && (
          <span className="tour-card__item--title b2 mt-2">
            Maximum of {props.tour.tourOptions[0].spotsTotal} people
          </span>
        )}
      </div>
    </Link>
  );
};

export default TourCard;
