/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";

import GlideCarousel from "~components/GlideCarousel";
import Layout from "~components/Layout";
import MailchimpForm from "~components/MailchimpForm";
import SEO from "~components/SEO";
import TourCard from "~components/TourCard";

import { AppContext } from "~context/AppContext";

class IndexPageComponent extends Component {
  state = { tourFeaturedImages: [] };

  tourFeaturedImages = [];

  componentDidMount() {
    const { allMarkdownRemark } = this.props;

    allMarkdownRemark.edges.forEach(edge => {
      if (edge.node.frontmatter.templateKey === `tour-page`) {
        this.tourFeaturedImages.push(edge.node.frontmatter);
      }
    });

    this.setState({ tourFeaturedImages: this.tourFeaturedImages });
  }

  //

  render() {
    const { appContext } = this.props;
    const {
      bigText,
      littleText,
      mailchimpTitle,
      mailchimpCTA,
      reviews
    } = this.props.frontmatter;

    return (
      <>
        <SEO
          frontmatterTitle={this.props.frontmatter.title}
          frontmatterDescription="JB Photohouse Photography Workshops"
          frontmatterKeywords={this.props.frontmatter.keywords}
          pathName={this.props.frontmatter.handle}
        />

        {appContext.popUpActive && (
          <div className="index-page__popup__wrapper text-light-grey shadow-lg z-20 bg-dark-grey fixed bottom-0 right-0 p-8 animation-appear">
            <button
              className="index-page__popup__close float-right -mt-10 -mr-8 p-4"
              type="button"
              onClick={appContext.closePopup}
            >
              x
            </button>

            <h3 className="grid-end--12 f4 pb-4 font-bold">{mailchimpTitle}</h3>

            <MailchimpForm
              formMessage={mailchimpCTA}
              onSubmit={this.closePopup}
              dark
            />
          </div>
        )}

        <Layout className="tours-page relative z-10 text-dark-grey">
          <section className="tours-page__tours relative w-full mb-8">
            <div className="grid">
              <h1
                className="f1 font-bold w-full mb-8 grid-end--12 animation-appear"
                style={{ animationDelay: `100ms` }}
              >
                {bigText}
              </h1>
              <h3
                className="f3 w-full grid-end--12 animation-appear xs:mb-2 mb-16"
                style={{ animationDelay: `200ms` }}
              >
                {littleText}
              </h3>
              <h3
                className="f3 font-bold w-full xs:mb-0 my-8 grid-end--12 animation-appear"
                style={{ animationDelay: `400ms` }}
              >
                Photography Workshop
                {this.state.tourFeaturedImages.length === 1 ? `` : `s`}
              </h3>

              {this.state.tourFeaturedImages.map((tourFeaturedImage, index) => {
                const imageKey = index;
                return (
                  <TourCard
                    key={imageKey}
                    tour={tourFeaturedImage}
                    className="animation-appear"
                    style={{ animationDelay: `${index * 100 + 500}ms` }}
                  />
                );
              })}
            </div>
          </section>

          {reviews && reviews.length > 0 && reviews[0].name !== `` && (
            <section className="index-page__reviews relative grid xs:py-0 py-8">
              <h3 className="f3 font-bold w-full grid-end--12">
                Customer Review{reviews.length === 1 ? `` : `s`}
              </h3>

              <GlideCarousel
                className="index-page__carousel relative xs:mt-4 mt-16 grid-end--12"
                colorClass="black"
                glideClass="reviews-glide"
                slideData={reviews}
              >
                {reviews.map((review, index) => {
                  const reviewIndex = index;
                  return (
                    <li
                      className="flex flex-col justify-center"
                      key={`review-${reviewIndex}`}
                    >
                      <div className="w-full xs:text-left text-center px-2">
                        <p
                          className="index-page__carousel__review m-auto whitespace-pre-wrap"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: review.review
                          }}
                        ></p>
                        <p className="mt-4 b2">- {review.name}</p>

                        {review.link && review.linkText && (
                          <a className="caption underline" href={review.link}>
                            {review.linkText}
                          </a>
                        )}
                      </div>
                    </li>
                  );
                })}
              </GlideCarousel>
            </section>
          )}
          <section className="index-page__form w-full flex flex-col py-16 justify-center items-center px-4">
            <h3 className="grid-end--8 grid-start--4 f3 pb-4 font-bold sm:max-w-xl max-w-2xl">
              {mailchimpTitle}
            </h3>

            <div className="w-full max-w-xl">
              <MailchimpForm formMessage={mailchimpCTA} />
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

const IndexPage = ({ data }) => {
  const { siteMetadata: metadata } = data.site;
  const { allMarkdownRemark } = data;
  const { frontmatter } = data.markdownRemark;

  return (
    <AppContext.Consumer>
      {appContext => (
        <IndexPageComponent
          allMarkdownRemark={allMarkdownRemark}
          appContext={appContext}
          frontmatter={frontmatter}
          metadata={metadata}
        />
      )}
    </AppContext.Consumer>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPage($id: String!) {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            handle
            itinerary
            keywords
            templateKey
            title
            tourOptions {
              price
              dateFrom
              dateTo
              spotsAvailable
              spotsTotal
            }
            tourType
            whatToExpect
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        keywords
        bigText
        littleText
        mailchimpCTA
        mailchimpTitle
        reviews {
          name
          review
          link
          linkText
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        keywords
        author
        url
        twitterUsername
      }
    }
  }
`;
