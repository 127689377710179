import React from "react";
import PropTypes from "prop-types";
import addToMailchimp from "gatsby-plugin-mailchimp";

import { validateEmail } from "~utils/helpers";

class MailChimpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ``,
      validationMessage: ``,
      validationMessageColor: `text-red`
    };
  }

  componentDidMount() {}

  //

  handleSubmit = e => {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    addToMailchimp(this.state.email)
      .then(data => {
        console.log(data.result);
        // alert(data.result);

        this.props.onSubmit();
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleEmailChange = event => {
    this.setState({ email: event.currentTarget.value });
  };

  //

  validate = () => {
    if (!validateEmail(this.state.email)) {
      this.setState({
        validationMessage: `Please enter a valid email address.`,
        validationMessageColor: `text-red`
      });
      return false;
    }

    this.setState({
      validationMessage: `Thank you, your email address has been received.`,
      validationMessageColor: `text-green`
    });

    return true;
  };

  //

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="mailchimp-form w-full">
        <h2 className="b2 pb-4">{this.props.formMessage}</h2>

        <div className="b1 w-full">
          <input
            className="p-2 w-3/4 xs:w-full max-w-md"
            placeholder="Email address"
            name="email"
            type="text"
            onChange={this.handleEmailChange}
          />

          <button
            className={`mailchimp-form__submit ${
              this.props.dark
                ? `text-dark-grey bg-light-grey`
                : `bg-dark-grey text-light-grey`
            } p-2 xs:w-full xs:mt-4 xs:ml-0 ml-2`}
            type="submit"
          >
            Subscribe
          </button>
        </div>

        <p className={`b2 pt-2 ${this.state.validationMessageColor}`}>
          {this.state.validationMessage}
        </p>
      </form>
    );
  }
}

MailChimpForm.defaultProps = {
  formMessage: `Subscribe to my mailing list.`,
  onSubmit: () => {},
  dark: false
};

MailChimpForm.propTypes = {
  formMessage: PropTypes.string,
  onSubmit: PropTypes.func,
  dark: PropTypes.bool
};

export default MailChimpForm;
